import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { VictoryPie, VictoryLabel } from 'victory'
import { COLORS, EFFECT_COLORS, shadeColor2, FONT_SIZES } from 'utils/styleHelpers'
import styled from '@emotion/styled'
import { PieWrapper, PieControls, PieControl, PieCheckBox } from './styles'

const SectionLabel = styled(VictoryLabel)`
	tspan {
		fill: ${COLORS.BLACK} !important;
		font-size: ${FONT_SIZES.H4} !important;
		font-family: '-apple - system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
			'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans - serif' !important;
		.dark & {
			fill: ${COLORS.WHITE} !important;
		}
	}
`

function EffectsChart({ category, effects, medical, negativeEffects }) {
	const [hasEffects, setHasEffects] = useState(true)
	const [hasMedical, setHasMedical] = useState(true)
	const [hasNegative, setHasNegative] = useState(true)

	function normalizeEffects(effects, baseColor, isActive) {
		const effectsTotal = effects.reduce((acc, effect) => acc + effect.score, 0)
		return effects.map((effect, idx) => ({
			x: idx,
			name: effect.name,
			y: isActive ? effect.score / effectsTotal : 0,
			fill: shadeColor2(baseColor, idx / effects.length),
			isActive,
		}))
	}
	const processEffects = useCallback(() => {
		return [
			...normalizeEffects(effects, EFFECT_COLORS.effects, hasEffects),
			...normalizeEffects(negativeEffects, EFFECT_COLORS.negative_effects, hasNegative),
			...normalizeEffects(medical, EFFECT_COLORS.medical, hasMedical),
		]
	}, [effects, hasEffects, hasMedical, hasNegative, medical, negativeEffects])

	const [chartData, setChartData] = useState(processEffects())
	useEffect(() => {
		setChartData(processEffects())
	}, [hasEffects, hasNegative, hasMedical, processEffects])
	return (
		<PieWrapper key={`${window.__theme}-chart`}>
			<svg height="100%" viewBox="0 0 600 400" width="100%">
				<VictoryPie
					animate={{ duration: 1000 }}
					data={chartData}
					height={400}
					innerRadius={68}
					labels={({ datum }) => (datum.isActive ? datum.name : '')}
					labelRadius={180}
					labelComponent={<SectionLabel />}
					standalone={false}
					style={{
						data: {
							fill: d => d.data[d.index].fill,
						},
					}}
					width={600}
				/>
				<circle cx={300} cy={200} fill={COLORS.GREEN} r={50} />
				<VictoryLabel
					style={{
						fontSize: 24,
						fill: COLORS.WHITE,
						fontFamily: `'-apple - system','BlinkMacSystemFont','Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue','sans - serif'`,
					}}
					text={category}
					textAnchor="middle"
					x={300}
					y={200}
				/>
			</svg>
			<PieControls>
				<PieControl bgc={EFFECT_COLORS.effects} controlName="hasEffects" isChecked={hasEffects}>
					<PieCheckBox
						checked={hasEffects}
						onChange={() => setHasEffects(!hasEffects)}
						type="checkbox"
						value="hasEffects"
					/>
					Effects
				</PieControl>
				<PieControl bgc={EFFECT_COLORS.medical} controlName="hasMedical" isChecked={hasMedical}>
					<PieCheckBox
						checked={hasMedical}
						onChange={() => setHasMedical(!hasMedical)}
						type="checkbox"
						value="hasMedical"
					/>
					Medical
				</PieControl>
				<PieControl bgc={EFFECT_COLORS.negative_effects} controlName="hasNegative" isChecked={hasNegative}>
					<PieCheckBox
						checked={hasNegative}
						onChange={() => setHasNegative(!hasNegative)}
						type="checkbox"
						value="hasNegative"
					/>
					Reactions
				</PieControl>
			</PieControls>
		</PieWrapper>
	)
}

EffectsChart.propTypes = {
	category: PropTypes.string,
	effects: PropTypes.array,
	medical: PropTypes.array,
	negativeEffects: PropTypes.array
}

export default EffectsChart
