import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import moment from 'moment'

function StrainSchema({ strain }) {
	const { name, category, description, imageURL, reviews, averageRating, totalReviews } = strain
	const schema = {
		'@context': 'http://www.schema.org',
		'@type': 'Product',
		name,
		category,
		description,
		image: imageURL,
	}
	const reviewSchema =
		reviews.length === 0
			? {}
			: {
					aggregateRating: {
						'@type': 'AggregateRating',
						ratingValue: averageRating && averageRating.toString(),
						ratingCount: totalReviews.toString(),
					},
					review: reviews.map(r => {
						const { dateCreated, rating, reviewerName, reviewText } = r
						return {
							'@type': 'Review',
							author: reviewerName,
							datePublished: moment(dateCreated).format('YYYY-MM-DD'),
							reviewBody: reviewText,
							reviewRating: {
								'@type': 'Rating',
								bestRating: '5',
								ratingValue: rating.toString(),
								worstRating: '1',
							},
						}
					}),
			  }
	return (
		<Helmet>
			<script type="application/ld+json">{JSON.stringify({ ...schema, ...reviewSchema })}</script>
		</Helmet>
	)
}

StrainSchema.propTypes = {
	strain: PropTypes.shape({
		averageRating: PropTypes.string,
		name: PropTypes.string,
		category: PropTypes.string,
		description: PropTypes.string,
		imageURL: PropTypes.string,
		reviews: PropTypes.array,
		totalReviews: PropTypes.string,
	}),
	reviews: PropTypes.array,
}
StrainSchema.defaultProps = {
	reviews: [],
}

export default StrainSchema
