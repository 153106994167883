import { Flex, Box } from '@rebass/emotion'
import styled from '@emotion/styled'
import { SPACERS, FONT_SIZES, MQ } from 'utils/styleHelpers'

export const AboutWrapper = styled(Flex)`
	margin-top: ${SPACERS.L};
	margin-left: ${SPACERS.L};
	margin-right: ${SPACERS.L};
`

export const InfoValue = styled.span`
	font-size: ${FONT_SIZES.H6};
	${MQ.L} {
		font-size: ${FONT_SIZES.H5};
	}
`
export const InfoLabel = styled.span`
	font-size: ${FONT_SIZES.H6};
	font-weight: 600;
	${MQ.L} {
		font-size: ${FONT_SIZES.H5};
	}
`

export const ShowMoreBox = styled(Box)`
	margin-top: ${SPACERS.S};
`
export const HeightSpacerBox = styled(Box)`
	height: 120px;
`
export const HeightSpacerBoxSmall = styled(Box)`
	height: 30px;
`

export const NoAuthForReview = styled.div`
	margin-top: ${SPACERS.L};
	text-align: center;
`
export const StrainDescription = styled.span`
	font-size: ${FONT_SIZES.H5};
`
export const InventoryMenuWrapper = styled(Box)``
