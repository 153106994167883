import React from 'react'
import PropTypes from 'prop-types'
import { Box, Flex } from '@rebass/emotion'
import ShowMore from 'react-show-more'
import { ThemeProvider } from 'emotion-theming'
import { Link } from 'gatsby'
import BottomDrawer from 'components/BottomDrawer'
import EffectsChart from '../EffectsChart'
import ReviewForm from 'components/ReviewForm'
import fullTheme from 'components/ReviewsList/ReviewFullTheme'
import ReviewsList from 'components/ReviewsList'
import StickyAd from 'components/StickyAd'
import InventoryMenu from 'components/InventoryMenu'
import Tabs, { Tab, TabList, TabPanel, TabPanels } from 'components/Tabs'
import { NODE_TYPES } from 'utils/constants'
import { authConditionReviewer } from 'utils/securityHelpers'
import { AboutWrapper, HeightSpacerBox, HeightSpacerBoxSmall, StrainDescription, InventoryMenuWrapper, NoAuthForReview, ShowMoreBox } from './styles'
import useAuthorization from 'hooks/useAuthorization'

function StrainTabs({
	activeTabIndex,
	category,
	description,
	effects,
	id,
	isMobile,
	medical,
	name,
	negativeEffects,
	reviews,
	slug
}) {
	const { isAuthorized, isLoading, authUser } = useAuthorization(authConditionReviewer)
	const activeIndex = !activeTabIndex ? isMobile ? 0 : 1 : activeTabIndex
	return <Tabs activeIndex={activeIndex}>
		<TabList>
			{isMobile && <Tab>About</Tab>}
			<Tab>Reviews</Tab>
			<Tab>Products</Tab>
			<Tab>{isMobile ? `Effects` : `Effects & More`}</Tab>
		</TabList>

		<TabPanels>
			<TabPanel>
				{isMobile && <>
					<AboutWrapper flexDirection="column" justifyContent="center">
						<ShowMoreBox>
							<ShowMore less="Show less" lines={8} more="Show more">
								<StrainDescription dangerouslySetInnerHTML={{
									__html: description
								}}></StrainDescription>
							</ShowMore>
						</ShowMoreBox>
						<HeightSpacerBox />
					</AboutWrapper>
					<StickyAd />
				</>}
			</TabPanel>
			<TabPanel>
				<Flex flexDirection="column">
					<Box>
						<ThemeProvider theme={fullTheme}>
							<ReviewsList reviews={reviews} />
						</ThemeProvider>
					</Box>
					<HeightSpacerBox />
					{isMobile && (
						<Box>
							{isAuthorized ? (
								<Box>
									<BottomDrawer>
										<ReviewForm nodeID={id} nodeLabel={name} nodeType={NODE_TYPES.STRAINS} slug={slug} strainCategory={category} />
									</BottomDrawer>
								</Box>
							) : (
									<NoAuthForReview>
										<Link to="/login">Login to leave your review!</Link>
									</NoAuthForReview>
								)
							}
						</Box>
					)}
				</Flex>
			</TabPanel>
			<TabPanel>
				<InventoryMenuWrapper>
					<InventoryMenu nodeId={id} nodeType={NODE_TYPES.STRAINS} isLinkingToDispensary />
				</InventoryMenuWrapper>
				<HeightSpacerBoxSmall />
			</TabPanel>
			<TabPanel>
				<EffectsChart category={category} effects={effects} medical={medical} negativeEffects={negativeEffects} />
			</TabPanel>
		</TabPanels>
	</Tabs>
}

StrainTabs.propTypes = {
	activeTabIndex: PropTypes.number,
	category: PropTypes.string,
	description: PropTypes.string,
	id: PropTypes.string,
	effects: PropTypes.array,
	medical: PropTypes.array,
	negativeEffects: PropTypes.array,
	isMobile: PropTypes.string,
	name: PropTypes.string,
	reviews: PropTypes.string,
	slug: PropTypes.string,
}
export default StrainTabs