import React, { useEffect, useRef, useContext } from 'react'
import PropTypes from 'prop-types'
import camelCase from 'camelcase'
import track, { TrackingPropType } from 'react-tracking'
import NodeTemplateBase from 'templates/nodeTemplateBase'
import { graphql } from 'gatsby'
import GlobalLayout from 'components/GlobalLayout'
import { stampTime, eventDispatch } from 'utils/tracker'
import { toSnakeCase } from 'utils/stringUtils'
import {
	EVENT_TYPES,
	TRACKING_ACTIONS,
	NODE_TYPES,
	STRAIN_SCORES_TYPES,
	STRAIN_IMAGE_SIZE,
	STRAIN_IMAGE_SIZE_DESKTOP,
} from 'utils/constants'
import StrainTabs from './components/StrainTabs'
import HeaderInfoAboutLarge from './components/HeaderInfoAboutLarge'
import HeaderInfoLeft from './components/HeaderInfoLeft'
import HeaderInfoRightTop from './components/HeaderInfoRightTop'
import { useCurrentBreakpointName } from 'react-socks'
import { SessionContext } from 'components/AuthContext'
import StrainSchema from 'components/StrainSchema'
function StrainTemplate({ data, location, tracking }) {
	const { sessionID, uid, userIp, userLocation, version } = useContext(SessionContext)
	const {
		allStrainsJson: { edges: strainData },
	} = data
	const node = useRef(null)
	const effects = useRef([])
	const negativeEffects = useRef([])
	const medical = useRef([])
	// handles if there are no scores for this strain
	// check this way so we don't query all scores in dev (drops the processing to 5 queries per second)

	const breakpoint = useCurrentBreakpointName()
	const isMobile = breakpoint === 'XS' || breakpoint === 'S'
	const { strain } = strainData[0].node
	const { averageRating, category, description, id, imageURL, name, reviews, slug, totalReviews } = strain
	const activeTabIndexRef = useRef(0)

	useEffect(() => {
		if (sessionID) {
			tracking.trackEvent({
				action: TRACKING_ACTIONS.VIEW_ACTION,
				event: EVENT_TYPES.WR_STRAIN_VIEW,
				nodeID: id,
				nodeType: NODE_TYPES.STRAINS,
				nodeLabel: name,
				strainCategory: category,
				sessionID,
				uid,
				userIp,
				userLocation,
				version,
			})
		}
	}, [sessionID])

	useEffect(() => {
		if (location.state && location.state.activeTabIndex) {
			activeTabIndexRef.current = location.state.activeTabIndex
		}
	}, [location])

	useEffect(() => {
		if (data.allStrainScoresJson) {
			const {
				allStrainScoresJson: { edges: scoreData },
			} = data
			const scores = scoreData.map(d => ({ ...d.node }))
			effects.current.push(...scores.filter(s => camelCase(s.type) === STRAIN_SCORES_TYPES.EFFECTS))
			negativeEffects.current.push(
				...scores.filter(n => toSnakeCase(n.type) === STRAIN_SCORES_TYPES.NEGATIVE_EFFECTS)
			)
			medical.current.push(...scores.filter(m => camelCase(m.type) === STRAIN_SCORES_TYPES.MEDICAL))
		}
	}, [])
	node.current = {
		HeaderInfoAboutLarge: <HeaderInfoAboutLarge />,
		HeaderInfoLeft: <HeaderInfoLeft />,
		HeaderInfoRightTop: <HeaderInfoRightTop />,
		averageRating,
		description,
		id,
		name,
		Tabs: StrainTabs({
			activeTabIndex: activeTabIndexRef.current,
			category,
			description,
			id,
			isMobile,
			name,
			reviews,
			slug,
			effects: effects.current,
			negativeEffects: negativeEffects.current,
			medical: medical.current,
		}),
		avatarWidth: [STRAIN_IMAGE_SIZE, STRAIN_IMAGE_SIZE_DESKTOP],
		avatarImgUrl: imageURL,
		nodeType: NODE_TYPES.STRAINS,
		totalReviews,
		strainCategory: category.toLowerCase(),
		slug,
	}
	return (
		<GlobalLayout location={location}>
			<StrainSchema strain={{ name, category, description, imageURL, reviews, averageRating, totalReviews }} />
			<NodeTemplateBase
				node={node.current}
				nodeType={NODE_TYPES.STRAINS}
				location={location}
				pageTitle={`${name} - ${category} - Flower Edibles and Concentrates - Weedrater`}
			/>
		</GlobalLayout>
	)
}
StrainTemplate.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object,
	tracking: TrackingPropType,
}
export default track({ timestamp: stampTime() }, { dispatch: data => eventDispatch(data) })(StrainTemplate)

export const pageQuery = graphql`
	query strainQuery($id: String!) {
		allStrainsJson(filter: { strain: { id: { eq: $id } } }) {
			edges {
				node {
					strain {
						id
						imageURL
						category
						description
						name
						percentSativa
						percentIndica
						slug
						totalReviews
						averageRating
						reviews {
							id
							dateCreated
							defaultAvatarThemeIndex
							profileImageURL
							prefersUsername
							username
							firstName
							lastName
							nodeName
							slug
							category
							rating
							reviewText
							reviewTitle
							reviewType
							userID
							reviewerName
						}
					}
				}
			}
		}
		allStrainScoresJson(filter: { strain: { eq: $id } }) {
			edges {
				node {
					score
					name
					type
					strain
				}
			}
		}
	}
`
