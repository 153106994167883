import styled from '@emotion/styled'
import { COLORS, MQ } from 'utils/styleHelpers'

export const PieWrapper = styled.div`
	border-radius: 1px;
	margin: 0px auto 0 auto;
	margin-bottom: 30px;
	width: 100%;
	padding: 10px;
	transition: background 0.5s;
	${MQ.L} {
		width: 70%;
	}
	.dark & {
		background: ${COLORS.MEDIUM_GRAY};
	}
`

export const PieControls = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-around;
	margin-bottom: 20px;
	margin-top: 40px;
`

export const PieControl = styled.label`
	appearence: button;
	color: ${props => (props.isChecked ? COLORS.WHITE : props.bgc)};
	background: ${props => (props.isChecked ? props.bgc : COLORS.WHITE)};
	border: 1px ${props => props.bgc} solid;
	border-radius: 3px;
	cursor: pointer;
	display: block;
	margin: 5px 10px;
	padding: 5px 10px;
	width: 100%;
	text-align: center;
	transition: background 0.5s, color 0.5s;
`
export const PieCheckBox = styled.input`
	position: absolute;
	left: -9999px;
	&:checked + label {
		color: red;
	}
`
